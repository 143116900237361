<template>
    <div class="content-box">
        <div class="main-box">
            <div class="tags">
                <span @click="goPost">【我要发帖】</span>
                <span @click="goCommunity">去论坛</span>
            </div>
            <div class="tabs-nav">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="我的帖子" name="first">
                    </el-tab-pane>
                    <el-tab-pane label="我的回帖" name="second">
                    </el-tab-pane>
                    <el-tab-pane label="我的回复" name="ww">
                    </el-tab-pane>
                    <el-tab-pane label="@我的" name="ee">
                    </el-tab-pane>
                    <el-tab-pane label="我的点赞" name="we">
                    </el-tab-pane>
                    <el-tab-pane label="我的收藏" name="qw">
                    </el-tab-pane>
                    <!-- <el-tab-pane label="我的足迹" name="ew">
                    </el-tab-pane> -->
                </el-tabs>
            </div>
            <!-- 我的帖子 -->
            <div v-if="current == 0">
                <div class="post-box" v-for="item in myPost" :key="item.id" @click="goDetail(item.id)">
                    <div class="post-img">
                        <img :src="$util.host + item.cover" alt="">
                    </div>
                    <div class="post-info">
                        <div class="title">
                            {{item.name}}
                            <i class="el-icon-delete" @click.stop="remove(item.id)"></i>
                        </div>
                        <div class="user">
                            <span>发帖人：{{item.sendUserName}}</span>{{item.createTime}}
                        </div>
                        <div class="content" v-html="item.content">

                        </div>
                    </div>
                    <div class="comment">
                        <div>评论 <span>{{item.commentNum}}</span></div>
                        <div>回复 <span>{{item.replyNum}}</span></div>
                    </div>
                </div>
            </div>
            <!-- 我的回帖 -->
            <div v-if="current == 1">
                <div class="reply" v-for="item in myReplyt" :key="item.id">
                    <div class="reply-title">
                        <div class="title"> {{item.articleName}}<span>{{item.typeText}}</span></div>
                        <div class="time">
                            {{item.createTime}} <i @click="removeReply(item.id)" class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div class="reply-content">
                        {{item.txt}}
                    </div>
                    <div class="comment">
                        <div @click="replyOpen(item)">回复</div>
                    </div>
                </div>
            </div>
            <!-- 我的回复 -->
            <div v-if="current == 2">
                <div class="reply" v-for="item in myAnswer" :key="item.id">
                    <div class="reply-title">
                        <div class="title">
                            <p>Re:</p> {{item.articleName}}<span>{{item.typeText}}</span>
                        </div>
                        <div class="time">
                            {{item.createTime}} <i @click="removeReply(item.id)" class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div class="reply-a">回复<span>@{{item.questionUserName}}</span></div>
                    <div class="reply-content">
                        {{item.question}}
                    </div>
                    <div class="reply-content">
                        {{item.txt}}
                    </div>
                    <div class="comment">
                        <!-- <div>回复</div> -->
                    </div>
                </div>
            </div>
            <!-- @我的 -->
            <div v-if="current == 3">
                <div class="reply" v-for="item in atMe" :key="item.id">
                    <div class="reply-title">
                        <div class="title">
                            <p>Re:</p> {{item.articleName}}<span>{{item.typeText}}</span>
                        </div>
                        <div class="time">
                            {{item.createTime}}
                        </div>
                    </div>
                    <div class="reply-a"><span>@{{item.questionUserName}}</span></div>
                    <div class="reply-content">
                        {{item.txt}}
                    </div>
                    <div class="comment">
                        <!-- <div>回复</div> -->
                    </div>
                </div>
            </div>
            <!-- 我的点赞 -->
            <div v-if="current == 4">
                <div class="post-box" v-for="item in myZan" :key="item.id" @click="goDetail(item.id)">
                    <div class="post-img">
                        <img :src="$util.host +item.cover" alt="">
                    </div>
                    <div class="post-info">
                        <div class="title">
                            {{item.name}}
                        </div>
                        <div class="user">
                            <span>发帖人：{{item.sendUserName||"平台"}}</span>{{item.createTime}}
                        </div>
                        <div class="content" v-html="item.content">

                        </div>
                    </div>
                    <div class="comment">
                        <div>评论 <span>{{item.commentNum}}</span></div>
                        <div>回复 <span>{{item.replyNum}}</span></div>
                    </div>
                </div>
            </div>
            <!-- 我的收藏 -->
            <div v-if="current == 5">
                <div class="post-box" v-for="item in myCollection" :key="item.id" @click="goDetail(item.id)">
                    <div class="post-img">
                        <img :src="$util.host +item.cover" alt="">
                    </div>
                    <div class="post-info">
                        <div class="title">
                            {{item.name}}
                        </div>
                        <div class="user">
                            <span>发帖人：{{item.sendUserName ||"平台"}}</span>{{item.createTime}}
                        </div>
                        <div class="content" v-html="item.content">

                        </div>
                    </div>
                    <div class="comment">
                        <div>评论 <span>{{item.commentNum}}</span></div>
                        <div>回复 <span>{{item.replyNum}}</span></div>
                    </div>
                </div>
            </div>
            <!-- 我的足迹 -->
            <!-- <div v-if="current == 6">
                <div class="post-box" v-for="item in 5" :key="item">
                    <div class="post-img"></div>
                    <div class="post-info">
                        <div class="title">
                            标题标题标题标题标题标题标题标题
                        </div>
                        <div class="user">
                            <span>发帖人：aaa</span>刚刚发布
                        </div>
                        <div class="content">文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本
                            文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本
                            文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本文本
                            文本文本文本文本文本</div>
                    </div>
                    <div class="comment">
                        <div>评论 <span>254</span></div>
                        <div>回复 <span>254</span></div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- 回复： -->
        <el-dialog v-if="replyShow" :visible.sync="replyShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div>回复: <span style="color:#e40013">{{itemInfo.articleName}}</span> </div>
            <div style="font-size:18px;margin:10px 0;">{{itemInfo.txt}}</div>
            <div class="commentInput">
                <!-- <p class="title">我要评论</p> -->
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" placeholder="" v-model="replyForm.txt" resize="none">
                </el-input>
                <div class="publicity">
                    <el-upload :limit="5" :action="action" list-type="picture-card" :on-success="replyBeforeLoad" :on-remove="replyRemoveImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="fot-btn">
                    <div @click="reply">回复</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {

    data() {
        return {
            activeName: 'first', //当前的下标  默认第一
            current: 0,//当前的下标  默认第一
            classList: [],  //所以分类列表
            myPost: [], //我的帖子
            myReplyt: [], //我的回帖
            myAnswer: [], //我的回复
            atMe: [], // 回复我的
            myZan: [], // 我的点赞
            myCollection: [], // 我的收藏
            replyShow: false, //回复弹出层
            action: this.$util.upload,
            itemInfo: null, //点击回复回去的item
            replyForm: { //回复参数
                txt: '',
                images: '',
                articleId: '',
                fid: null,
                type: 1,
            },

        };
    },

    mounted() {
        this.getClass()
    },

    methods: {
        // 获取所有分类
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                this.classList = res.data
                this.getMyPost()
            })
        },
        //我的帖子
        getMyPost() {
            this.$util.post('/center/create/article/create').then(res => {
                console.log(res.data);
                this.myPost = res.data
            })
        },
        //我的回帖
        getMyReplyt() {
            this.$util.post('/center/create/article/reply').then(res => {
                res.data.forEach(item => {
                    this.classList.forEach(el => {
                        if (item.articleClassifyId == el.id) {
                            this.$set(item, 'typeText', el.name)
                        }
                    })
                })
                this.myReplyt = res.data
            })
        },
        // 我的回复
        getMyAnswer() {
            this.$util.post('/center/create/article/answer').then(res => {
                res.data.forEach(item => {
                    this.classList.forEach(el => {
                        if (item.articleClassifyId == el.id) {
                            this.$set(item, 'typeText', el.name)
                        }
                    })
                })
                this.myAnswer = res.data
            })
        },
        // 回复我的 @我的
        getAtMe() {
            this.$util.post('/center/create/article/at/me').then(res => {
                res.data.forEach(item => {
                    this.classList.forEach(el => {
                        if (item.articleClassifyId == el.id) {
                            this.$set(item, 'typeText', el.name)
                        }
                    })
                })
                this.atMe = res.data

            })
        },
        // 我的点赞
        getMyZan() {
            this.$util.post('/center/create/article/fabulous').then(res => {
                this.myZan = res.data
            })
        },
        // 我的收藏
        getMyCollection() {
            this.$util.post('/center/create/article/collection').then(res => {
                this.myCollection = res.data
            })
        },
        // 删除
        remove(id) {
            this.$util.post('/center/create/article/delete', { id }).then(res => {
                if (res.code == 200) {
                    this.getMyPost()

                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 删除回帖
        removeReply(id) {
            this.$util.post('/center/create/remove/evaluate', { evaluateId: id }).then(res => {
                if (res.code == 200) {
                    this.getMyAnswer()
                    this.getMyReplyt()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 点击跳转时的对应下标回调
        handleClick(tab) {
            if (tab.index == 0) {
                this.getMyPost()
            } else if (tab.index == 1) {
                this.getMyReplyt()
            } else if (tab.index == 2) {
                this.getMyAnswer()
            } else if (tab.index == 3) {
                this.getAtMe()
            } else if (tab.index == 4) {
                this.getMyZan()
            } else if (tab.index == 5) {
                this.getMyCollection()
            }
            this.current = tab.index;
        },
        // 去发帖
        goPost() {
            this.$router.push({ path: '/post' })

        },
        // 论坛中心
        goCommunity() {
            this.$router.push({ path: '/community' })
        },
        // 看详情
        goDetail(id) {
            this.$router.push({ path: "communityDetail", query: { id } });
        },
        // 打开评论
        replyOpen(item) {
            this.replyShow = true
            this.itemInfo = item
            this.replyForm.articleId = item.sourceId
            this.replyForm.fid = item.id
        },
        // 上传
        replyBeforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.replyForm.images = imgs.join(',')
        },
        replyRemoveImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.replyForm.images = imgs.join(',')
        },
        // 回复/
        reply() {
            if (this.replyForm.txt == '') {
                return this.$message.error('回复内容不能为空');
            }
            this.$util.post('/forum/action/comment', this.replyForm).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.getMyReplyt()
                    this.replyShow = false
                } else {
                    this.$message.error(res.message);
                }

            })
        },
        handleClose(done) {
            this.replyShow = false
        },
    },
};
</script>

<style lang="scss" scoped>
.content-box {
    padding: 30px 0 0;
    background: #f6f6f6;
    min-height: 400px;
}
.main-box {
    width: 1200px;
    margin: 0 auto;
    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;
        color: #222;
        span {
            margin-left: 40px;
            cursor: pointer;
        }
    }
}
.tabs-nav {
    padding: 30px 30px 10px;
    margin: 30px 0 0;
    background: #fff;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
}
/deep/.el-tabs__item {
    font-size: 20px;
    color: #666666;
    width: 160px;
    height: 50px;
    font-weight: bold;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
/deep/.el-tabs__header {
    margin: 0;
}
.post-box {
    position: relative;
    padding: 30px;
    background: #fff;
    margin-top: 30px;
    display: flex;
    .comment {
        display: flex;
        font-size: 14px;
        align-items: center;
        position: absolute;
        right: 30px;
        bottom: 13px;
        color: #666666;
        div {
            margin-left: 30px;
            span {
                color: #222;
            }
        }
    }
    .post-img {
        width: 198px;
        height: 198px;
        margin-right: 20px;
        // background: red;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .post-info {
        flex: 1;
        max-height: 198px;
        overflow: hidden;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 22px;
            font-weight: bold;
            color: #222;
            i {
                font-size: 30px;
            }
        }
        .user {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            color: #666;
            margin: 12px 0 11px;
            span {
                margin-right: 70px;
            }
        }
        .content {
            height: 84px;
            width: 912px;
            font-weight: 400;
            font-size: 16px;
            color: #666;
            /deep/img {
                max-width: 100%;
                height: 100px;
            }
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.reply {
    position: relative;
    padding: 30px;
    background: #fff;
    margin-top: 30px;
    .comment {
        display: flex;
        font-size: 14px;
        align-items: center;
        position: absolute;
        right: 30px;
        bottom: 13px;
        color: #666666;
        div {
            margin-left: 30px;
            span {
                color: #222;
            }
        }
    }
}
.reply-a {
    margin-top: 20px;
    font-size: 20px;
    color: #666;
    span {
        color: #e40012;
    }
}
.reply-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    color: #222;
    font-weight: bold;
    padding-bottom: 30px;
    border-bottom: 1px solid #eeeeee;
    .title {
        p {
            margin-right: 10px;
        }
        display: flex;
        align-items: center;
        span {
            color: #2ea7e0;
            margin-left: 70px;
            font-size: 14px;
        }
    }
    .time {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
        i {
            margin-left: 70px;
            font-size: 30px;
        }
    }
}
.reply-content {
    margin: 30px 0 10px;
    padding-bottom: 30px;
    font-size: 16px;
    color: #666;
    border-bottom: 1px solid #eee;
}
.commentInput {
    padding: 30px;
    background: #ffffff;
    margin-top: 30px;
    margin-bottom: 20px;

    .addImg {
        font-size: 18px;
        color: #e40012;
        padding-left: 30px;
        cursor: pointer;
    }

    .title {
        color: #222222;
        font-size: 22px;
        font-weight: bold;
    }
}
.publicity {
    padding: 30px 0;
    display: flex;
    align-items: center;
}
.fot-btn {
    display: flex;
    justify-content: flex-end;
    div {
        cursor: pointer;
        font-size: 18px;
        background: #e40012;
        color: #fff;
        padding: 10px 60px;
    }
}
.imgs {
    display: flex;
    align-items: center;
    margin: 10px 0;
    img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
</style>